import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../../firebase';
import { BlockPicker, ChromePicker } from 'react-color';
import reactCSS from 'reactcss';
import SiteFooterBrand from '../../../../components/site-footer-brand';
import SiteHeader from '../../../../components/site-header';
import SiteNav from '../../../../components/site-nav';
import RelationshipForm from '../../../../components/relationship-form';
import Modal from '../../../../components/global/modal';
import withAuthorisation from '../../../../components/withauthorisation';
import Breadcrumbs from '../../../../components/breadcrumbs';
const authCondition = (authUser) => !!authUser;
//

class Edit360Relationships extends Component {
  constructor(props) {
    super(props);

    let editLink;
    let editLinkQuestionnaire;
    let editLinkBrand;

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      current360Relationships: this.props.current360Relationships,
      showNewRelationshipModal: false,
      loaded360: false,
      displayPrimaryColourPicker: false,
      relationshipcolour: '',
    };
    this.onSubmitRelationship = this.onSubmitRelationship.bind(this);
    this.deleteRelationship = this.deleteRelationship.bind(this);
    this.updateAfterReplace = this.updateAfterReplace.bind(this);
    this.moveRelationship = this.moveRelationship.bind(this);
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    window.scrollTo(0, 0);
    this.adminLink = `/dashboard/`;
    this.editLink = `/dashboard/360s/${params.id}/edit/`;
    this.editLinkStandard = `/dashboard/360s/${params.id}/edit/standard/`;
    this.editLinkBrand = `/dashboard/360s/${params.id}/edit/brand/`;
    const current360Relationships = this.props.current360Relationships;

    if (current360Relationships) {
      let relationshipsList;
      if (
        Object.values(current360Relationships)
          .map((relationship) => relationship.sortOrder)
          .includes(undefined)
      ) {
        relationshipsList = Object.keys(this.props.current360Relationships);
      } else {
        relationshipsList = Object.keys(this.props.current360Relationships).sort((a, b) => {
          const relationships = this.props.current360Relationships;
          return relationships[a].sortOrder - relationships[b].sortOrder;
        });
      }
      this.setState({
        relationshipsList: relationshipsList,
      });
    }
  }

  deleteRelationship(accountId, current360Id, relationshipKey, relationshipNum) {
    db.doDeleteRelationship(accountId, current360Id, relationshipKey).then((data) => {
      let delRel = this.state.current360Relationships;
      delete delRel[relationshipKey];
      for (const key in delRel) {
        let orderNum = delRel[key].sortOrder;
        if (orderNum && orderNum >= relationshipNum) {
          const relationshipData = {
            name: delRel[key].name,
            colour: delRel[key].colour,
            description: delRel[key].description,
          };
          db.doUpdateRelationship(accountId, current360Id, key, relationshipData, orderNum - 1).then(() => {
            delRel[key].sortOrder = orderNum - 1;
            this.setState({
              delRel,
            });
            window.location.reload();
          });
        }
      }
      window.location.reload();
    });
  }

  onSubmitRelationship(e) {
    let relationshipData = {
      name: this.relationshipName.value,
      colour: this.relationshipColour.value,
      description: this.relationshipDescription.value,
    };
    e.preventDefault();
    if (this.state.relationshipsList !== undefined) {
      db.doCreateNewRelationship(
        this.state.accountId,
        this.state.current360ID,
        this.relationshipLabel.value,
        relationshipData,
        this.state.relationshipsList.length
      ).then((data) => {
        this.setState({ showNewRelationshipModal: false });
        db.doGetCurrent360Relationships(this.props.match.params.accountId, this.props.match.params.id).then((snapshot) => {
          let relationships = {};
          snapshot.docs.map((doc, i) => {
            relationships[doc.id] = doc.data();
          });
          this.setState({
            current360Relationships: relationships,
            relationshipsList: Object.keys(relationships),
          });
          window.location.reload();
        });
      });
    }
  }

  updateAfterReplace(relationships) {
    this.setState({
      current360Relationships: relationships,
    });
  }

  moveRelationship(accountID, current360Id, toIncrease, toDecrease) {
    let relationships = this.props.current360Relationships;
    for (const key in relationships) {
      const relationshipData = {
        name: relationships[key].name,
        colour: relationships[key].colour,
        description: relationships[key].description,
      };
      if (toIncrease === relationships[key].sortOrder) {
        relationships[key].sortOrder = toDecrease;
        db.doUpdateRelationship(accountID, current360Id, key, relationshipData, toDecrease);
      } else if (toDecrease === relationships[key].sortOrder) {
        relationships[key].sortOrder = toIncrease;
        db.doUpdateRelationship(accountID, current360Id, key, relationshipData, toIncrease);
      }
    }
    const relationshipsList = Object.keys(relationships).sort((a, b) => {
      return relationships[a].sortOrder - relationships[b].sortOrder;
    });
    this.setState({
      current360Relationships: relationships,
      relationshipsList: relationshipsList,
    });
  }

  render() {
    const pickerStyles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        primarycolorinput: {
          borderColor: this.state.relationshipcolour,
          borderWidth: '2px',
        },
      },
    });
    const relationshipsList = this.state.relationshipsList;
    return (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={this.state.current360}
            accountData={this.props.accountData}
            current360Groups={this.props.current360Groups}
            current360Relationships={this.props.current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${this.state.accountId}`,
              },
              {
                title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                  this.props.current360.type
                } Projects`,
                to: `/dashboard/${this.props.match.params.accountId}/${this.props.current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
              },
              {
                title: this.state.current360.title,
                to: '../',
              },
              {
                title: 'Advanced Settings',
                to: null,
              },
            ]}
          />

          <h1>{this.state.current360.title}: Relationship Settings</h1>

          <div className='bg-white mv4 ph4 pv4 shadow br2'>
            <div className='flex'>
              <div className='w-50'>
                <p>
                  <strong>Change relationship labels</strong>
                </p>
                <p>You can manage the labels assigned to 'reviewer groups' below. You can also add additional relationships, if required.</p>
                <p>
                  The relationship number indicates the order in which they are listed in your report, with #1 being first. You can change the position of a
                  relationship by using the arrow buttons to the right of them.
                </p>
                <p>* Note - Relationship data cannot be changed once a 360 Project is collecting data (I.E. at least one group is active)</p>
              </div>
              {this.props.current360.statusCode == 1 || this.props.current360.statusCode == 0 ? (
                Object.keys(this.state.current360Relationships).length >= 6 ? (
                  <button className='btn ml-auto disabled' disabled>
                    Relationship Limit Reached
                  </button>
                ) : (
                  <button className='btn ml-auto' onClick={() => this.setState({ showNewRelationshipModal: true })}>
                    Create New Relationship
                  </button>
                )
              ) : (
                ''
              )}
            </div>
            <hr className='mt4' />
            {relationshipsList ? (
              relationshipsList.map((key, i) => {
                return (
                  <RelationshipForm
                    key={key}
                    accountId={this.state.accountId}
                    current360Id={this.state.current360ID}
                    current360StatusCode={this.props.current360.statusCode}
                    relationshipNum={i + 1}
                    relationshipKey={key}
                    relationship={this.state.current360Relationships[key]}
                    deleteCurrent={this.deleteRelationship}
                    relationships={this.state.current360Relationships}
                    updateAfterReplace={this.updateAfterReplace}
                    moveRelationship={this.moveRelationship}
                    relationshipsList={relationshipsList}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>

          <Modal
            isOpen={this.state.showNewRelationshipModal}
            contentLabel='Add a new relationship'
            onClose={() => this.setState({ showNewRelationshipModal: false })}
            content={
              Object.keys(this.state.current360Relationships).length >= 6 ? (
                <div className='tc'>
                  <h2>Relationships Limit Reached</h2>
                  <p>You have reached the maximum number of relationships allowed per 360</p>
                </div>
              ) : (
                <div>
                  <h2>Create a new Relationship</h2>
                  <form className='mw6 center pt2 pb2' onSubmit={this.onSubmitRelationship}>
                    <label htmlFor='title'>Label</label>
                    <input
                      required
                      maxLength='3'
                      ref={(input) => (this.relationshipLabel = input)}
                      aria-describedby='name-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      type='text'
                      placeholder='Eg: LM'
                    />
                    <label htmlFor='title'>Name</label>
                    <input
                      required
                      ref={(input) => (this.relationshipName = input)}
                      aria-describedby='name-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      type='text'
                      placeholder='Eg: Line Manager'
                    />
                    <label htmlFor='title'>Colour</label>
                    <input
                      required
                      ref={(input) => (this.relationshipColour = input)}
                      onClick={(e) => this.setState({ displayPrimaryColourPicker: true })}
                      aria-describedby='name-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      type='text'
                      value={this.state.relationshipcolour}
                      placeholder='#000000'
                    />
                    {this.state.displayPrimaryColourPicker ? (
                      <div style={pickerStyles.popover}>
                        <div
                          style={pickerStyles.cover}
                          onClick={(e) => {
                            this.setState({ displayPrimaryColourPicker: false });
                          }}
                        />
                        <ChromePicker color={this.state.relationshipcolour} onChange={(color) => this.setState({ relationshipcolour: color.hex })} />
                      </div>
                    ) : null}
                    <label htmlFor='title'>Description</label>
                    <textarea ref={(input) => (this.relationshipDescription = input)} />
                    <button className='btn' type='submit'>
                      Submit
                    </button>
                  </form>
                </div>
              )
            }
          />
          <Link
            to={{
              pathname: '../',
              current360: this.props.current360,
              accountData: this.props.accountData,
            }}
            className='btn btn--back'
          >
            Return to Advanced Settings
          </Link>
        </section>
      </div>
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360Relationships);
