import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import Autocomplete from 'react-autocomplete';
import LoadingState from '../../../components/global/loading-state';
import Breadcrumbs from '../../../components/breadcrumbs';
import SiteFooter from '../../../components/site-footer';
import SiteHeader from '../../../components/site-header';
import SiteNav from '../../../components/site-nav';
import QuestionnaireSectionEditor from '../../../components/questionnairesectioneditor';
import Modal from '../../../components/global/modal';
import { breadcrumbSelect } from '../../../helpers';

//
import { compose } from 'recompose';
import withAuthorisation from '../../../components/withauthorisation';
import withUser360s from '../../../components/withuser360s';
const authCondition = (authUser) => !!authUser;
//

let existing360ItemList = [];

class Edit360Questionnaire extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    let dashboardLink = '/dashboard/';
    let editLink = `/dashboard/360s/${params.id}/edit/`;
    let coreSettingsLink = `/dashboard/360s/${params.id}/edit/basic/`;

    this.openNewSectionModal = this.openNewSectionModal.bind(this);
    this.openCopyQuestionnaireModal = this.openCopyQuestionnaireModal.bind(this);
    this.onSubmitNewSection = this.onSubmitNewSection.bind(this);
    this.copyCompetencies = this.copyCompetencies.bind(this);
    // this.duplicateSection = this.duplicateSection.bind(this)
    this.updateSectionsCount = this.updateSectionsCount.bind(this);
    this.updateSectionsLegacyMode = this.updateSectionsLegacyMode.bind(this);
    this.updateAutoCompleteValue = this.updateAutoCompleteValue.bind(this);
    this.valueSelect = this.valueSelect.bind(this);
    this.getItemValue = this.getItemValue.bind(this);
    this.shouldItemRender = this.shouldItemRender.bind(this);

    this.state = {
      loading: true,
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      questionnaireID: this.props.current360 ? this.props.current360.questionnaireID : undefined,
      existing360QuestionnaireIDs: null,
      projectIdToCopy: '',
      questionnaireIdToCopy: '',
      questionnaireLoaded: null,
      newSectionModalOpen: false,
      copyQuestionnaireModalOpen: false,
      duplicatingSectionId: null,
      dashboardLink: dashboardLink,
      editLink: editLink,
      coreSettingsLink: coreSettingsLink,
      creatingNewSection: false,
      sectionsCount: 0,
      sectionsLegacyMode: false,
    };
  }

  updateSectionsCount(count) {
    this.setState({
      sectionsCount: count,
    });
  }

  updateSectionsLegacyMode(snapshot) {
    // Checks if for any section objects which lack a sortOrder property and updates sectionsLegacyMode state to true if any found
    const sortOrderPresence = snapshot.docs.map((doc) => doc.data().sortOrder);
    if (sortOrderPresence.includes(undefined)) {
      this.setState({
        sectionsLegacyMode: true,
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const {accountId} = this.state;

    db.doGet360s(accountId).then((snapshot) => {
      if (!snapshot.empty) {
        let user360s = {};
        let existing360sObjectsArray = [];
        let titlesArray = [];
        let duplicateTitleIDs = [];
        snapshot.docs.forEach((doc) => {
          let {type, title, createdDate, statusCode} = doc.data();
          let displayDate;
          const {id} = doc;
          user360s[id] = doc.data();
          // ensures copy competencies option only contains 360 projects with at least 2 competencies that have statements present
          if (type === '360' && statusCode > 0) {
            // checks if duplicate of title and logs the index of the existing one
            const existingTitleIndex = titlesArray.indexOf(title);
            if (existingTitleIndex > -1) {
              // gets the id for the existing duplicate title and check if it already exists in the array of duplicates, push if not
              const existingTitleID = existing360sObjectsArray[existingTitleIndex].id;
              if (!duplicateTitleIDs.includes(existingTitleID)) {
                duplicateTitleIDs.push(existingTitleID)
                // adds the converted date, for the existing duplicate title
                existing360sObjectsArray[existingTitleIndex]['displayDate'] = new Date(existing360sObjectsArray[existingTitleIndex]['createdDate']).toLocaleString();
              }
              // now pushes the current id to the array of duplicates
              duplicateTitleIDs.push(id);
              displayDate = new Date(createdDate).toLocaleString();
            }
            // once duplicate checks are complete, the currrent title can be pushed to it's respective array
            titlesArray.push(title)
            // the data collected is put into an object and pushed to existing360sObjectsArray;
            let projectData = {title, createdDate, id};
            if (displayDate) {
              // adds the converted date, for a duplicate title
              projectData.displayDate = displayDate;
            }
            existing360sObjectsArray.push(projectData);
          }
        });

        this.setState({
          existing360QuestionnaireIDs: user360s,
          existing360sObjectsArray,
          duplicateTitleIDs,
          loaded360: true,
          loading: false,
        });
      }
    });
  }

  openNewSectionModal() {
    this.setState({
      newSectionModalOpen: true,
    });
  }

  openCopyQuestionnaireModal() {
    this.setState({
      copyQuestionnaireModalOpen: true,
    });
  }

  onSubmitNewSection(event) {
    const {current360} = this.state;
    this.setState({
      creatingNewSection: true,
    });
    let currentForm = event.currentTarget;
    db.doCreateNewQuestionnaireSection(
      this.state.accountId,
      this.state.questionnaireID,
      this.title.value,
      this.description.value,
      this.state.sectionsCount,
      current360 ? current360.type : null
    ).then((snapshot) => {
      currentForm.reset();
      this.setState({
        newSectionModalOpen: false,
        creatingNewSection: false,
      });
    });
    event.preventDefault();
  }

  copyCompetencies(e) {
    let currentForm = e.currentTarget;
    const {accountId, questionnaireID, sectionsCount, projectIdToCopy, existing360QuestionnaireIDs} = this.state;
    const questionnaireIdToCopy = existing360QuestionnaireIDs[projectIdToCopy].questionnaireID;

    db.copyCompetenciesTo360(accountId, questionnaireIdToCopy, questionnaireID, sectionsCount)
      .then((data) => {
        currentForm.reset();
        this.setState({
          projectIdToCopy: '',
          copyQuestionnaireModalOpen: false,
          questionnaireIdToCopy: null,
        });
      })
      .catch((error) => {
        alert('cannot copy!');
      });
    currentForm.reset();
    e.preventDefault();
  }

  updateAutoCompleteValue(e, value) {
    e.preventDefault();
    // updates with typed text from input
    this.setState({ autoComplete: value })
  }

  valueSelect(value, item) {
    // updates with data from selected dropdown item
    this.setState({ autoComplete: value, projectIdToCopy: item.id})
  }

  getItemValue(item) {
    return item.title;
  }

  shouldItemRender(item, value) {
    // runs regexp search so only dropdown items relevant to text search are displayed
    const searchTerm = new RegExp(value, "i");
    return item.title.search(searchTerm) > -1;
  }

  render() {
    const {accountId, loading, current360, accountData, userDetails, accountTitle, current360ID, btnBrandCol, sectionsCount, sectionsLegacyMode, newSectionModalOpen, creatingNewSection, copyQuestionnaireModalOpen, existing360sObjectsArray, duplicateTitleIDs, questionnaireID} = this.state
    const {current360Groups, current360Relationships} = this.props

    let breadcrumbLinks = [
      {
        title: 'Dashboard',
        to: `/dashboard/${accountId}/`,
      },
    ];

    let currentPageBreacrumb = {to: null}

    if (current360) {
      currentPageBreacrumb.title = `${current360.type === 'Goal Tracker' ? 'Goal' : 'Questionnaire'} Set-Up`;
      // add link to respective projects page
      breadcrumbLinks.push(
        {
          title: `${accountData.accountTitle ? accountData.accountTitle : accountData.title} ${current360.type} Projects`,
          to: `/dashboard/${accountId}/${breadcrumbSelect(current360.type)}-projects/`,
        },
      )
      // add link to specific project
      breadcrumbLinks.push(
        {
          title: current360.title,
          to: '../',
        },
      )
    } else {
      currentPageBreacrumb.title = 'Your Default Questionnaire';
      // add link to account settings page
      breadcrumbLinks.push(
        {
          title: `${accountData.accountTitle ? accountData.accountTitle : accountData.title}`,
          to: `/dashboard/${accountId}/account/`,
        },
      )
    }

    // add current page breadcrumb
    breadcrumbLinks.push(currentPageBreacrumb)



    return (
      <div className='page-wrapper'>
        {loading ? (
          <LoadingState />
        ) : (
          <section className='container container--slim'>
            <Breadcrumbs
              current360={current360}
              userDetails={userDetails}
              accountTitle={accountTitle}
              accountData={accountData}
              current360Groups={current360Groups}
              current360Relationships={current360Relationships}
              links={breadcrumbLinks}
            />

            <h1>
              {current360 ? current360.title + ':' : 'Default'} {current360 && current360.type === 'Goal Tracker' ? 'Goal' : 'Questionnaire'} Set-Up
            </h1>

            <div className='bg-white mv4 ph4 pv4 shadow br2'>
              {current360 && current360.type === 'Goal Tracker' ? (
                <div className='w-100 flex justify-between'>
                  <div className='w-50'>
                    <p>Add/Edit Goals</p>
                    <p>
                      Each goal is made up of a series of SMART questions. Click ‘Edit Goal' to amend or delete items within each goal, in line with your
                      project requirements.
                    </p>
                    <p>
                      Hit the <strong>Create A New Goal</strong> button to add the number of goals you want to track.
                    </p>
                  </div>
                  <button onClick={this.openNewSectionModal} className='btn'>
                    Create A New Goal
                  </button>
                </div>
              ) : (
                <div>
                  <p>
                    <strong>Add Competencies & Statements</strong>
                  </p>
                  <p>
                    Competencies can be understood as high-level collections/groups of skills or knowledge. Statements are the series of actions or behaviours,
                    which are used to describe each competency area. Participants provide their feedback in response to the statements you define here.
                  </p>
                  <div className='flex justify-between w-100'>
                    <button onClick={this.openNewSectionModal} className='btn input-reset button-reset bn w-40'>
                      Create New Competency
                    </button>
                    <button onClick={this.openCopyQuestionnaireModal} className='btn input-reset button-reset bn w-40'>
                      Copy Existing Competencies & Statements
                    </button>
                  </div>
                </div>
              )}

              <hr className='mv4' />
              <QuestionnaireSectionEditor
                accountId={accountId}
                current360={current360}
                current360Id={current360ID}
                accountData={accountData}
                questionnaireID={questionnaireID}
                current360StatusCode={current360 ? current360.statusCode : undefined}
                btnBrandCol={btnBrandCol}
                sectionsCount={sectionsCount}
                updateSectionsCount={this.updateSectionsCount}
                updateSectionsLegacyMode={this.updateSectionsLegacyMode}
                sectionsLegacyMode={sectionsLegacyMode}
              />
            </div>

            <div className='flex justify-between'>
              <Link to={{ pathname: '../' }} className='btn btn--back'>
                Return to {this.props.current360 ? 'Project' : 'Account'} Settings
              </Link>
              <Link
                to={{
                  pathname: `preview/`,
                  current360: current360 ? current360 : '',
                  accountData: accountData,
                  sectionsLegacyMode: sectionsLegacyMode,
                }}
                className={`btn ${sectionsCount < 1 ? 'btn--disabled' : ''}`}
              >
                Preview Your {current360 && current360.type === 'Goal Tracker' ? 'Goals' : 'Questionnaire'}
              </Link>
            </div>

            <Modal
              isOpen={newSectionModalOpen}
              contentLabel='Add a new 360'
              onClose={() => this.setState({ newSectionModalOpen: false })}
              content={
                <div>
                  <h2>Create new {current360 && current360.type === 'Goal Tracker' ? 'Goal' : 'competency'}</h2>
                  <form className='mw6 center pt2 pb2' onSubmit={this.onSubmitNewSection}>
                    <label htmlFor='title' className='f7 b db mb2'>
                      Name
                    </label>
                    <input
                      required
                      ref={(input) => (this.title = input)}
                      aria-describedby='name-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      type='text'
                      placeholder={current360 && current360.type === 'Goal Tracker' ? 'Name your goal' : 'Name your section'}
                    />
                    <label htmlFor='description' className={current360 && current360.type === 'Goal Tracker' ? 'f7 b dn mb2' : 'f7 b db mb2'}>
                      Description
                    </label>
                    <textarea
                      ref={(input) => (this.description = input)}
                      aria-describedby='description-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      rows='6'
                      placeholder='Give your competency a description'
                      hidden={current360 && current360.type === 'Goal Tracker'}
                    />
                    <button className={creatingNewSection ? 'btn btn--disabled' : 'btn'} type='submit' disabled={creatingNewSection}>
                      Submit
                    </button>
                  </form>
                </div>
              }
            />

            <Modal
              isOpen={copyQuestionnaireModalOpen}
              contentLabel='Import Existing Questionnaire'
              onClose={() => this.setState({ copyQuestionnaireModalOpen: false })}
              content={
                <div>
                  <form className='mw6 center pt2 pb2' onSubmit={this.copyCompetencies}>
                    {/*
                            // TODO: MAKE THIS DROPDOWN into a styled dropdown
                          */}

                    <label htmlFor='existing360' className='f7 b db mb2'>
                      Select an existing 360 to copy from
                    </label>
                    {duplicateTitleIDs.length > 0 && (
                      <p>There are multiple projects with the same name. To tell them apart; these will be displayed with a created date below their title.</p>
                    )}
                    <div>
                      <Autocomplete
                        items={existing360sObjectsArray}
                        shouldItemRender={(item, value) => this.shouldItemRender(item, value)}
                        getItemValue={(item) => this.getItemValue(item)}
                        renderItem={(item, isHighlighted) => (
                          <div
                            key={item.id}
                            className='input-reset bb bl br b--black-20 pa2 db w-100'
                            style={{background: isHighlighted ? 'lightgray' : 'white'}}
                          >
                            {item.title}
                            {item.displayDate && (
                              <>
                                <br/>
                                <small className='b'>{item.displayDate}</small>
                              </>
                            )}
                          </div>
                        )}
                        renderInput={(props) => (
                          <input
                            required
                            aria-describedby='client-desc'
                            {...props}
                            className='input-reset ba b--black-20 pa2 mb4 db w-100'
                            type='text'
                            placeholder='Select a Client, or create new'
                          />
                        )}
                        value={this.state.autoComplete}
                        onChange={(e, value) => this.updateAutoCompleteValue(e, value)}
                        onSelect={(value, item) => this.valueSelect(value, item)}
                        wrapperStyle={{
                          position: 'relative',
                          zIndex: '999',
                        }}
                        menuStyle={{
                          top: '100%',
                          left: 0,
                          position: 'absolute',
                        }}
                      />
                    </div>
                    <button className='btn mt4' type='submit'>
                      Copy competencies
                    </button>
                  </form>
                </div>
              }
            />
          </section>
        )}
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Edit360Questionnaire);

